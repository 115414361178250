/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';

import ExpandMore from '@mui/icons-material/ExpandMore';

import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ExploreIcon from '@mui/icons-material/Explore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// import { MessageOutlined } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
// import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RateReviewIcon from '@mui/icons-material/RateReview';
// import { useRouter } from 'next/router';

import { colors } from '@app/config/colors';
import Typography from '@app/components/atoms/Typography';

import { useQuery } from '@apollo/client';
import { GET_SIDEBAR_STATUS, USER_QUERY } from '@app/apollo/queries';
import { useRouter } from 'next/router';
import { isOrganiser } from '@app/helpers';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Tooltip from '@mui/material/Tooltip';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './style';
import SidebarItem from './SidebarItem';
import ExpandSidebarItem from './ExpandSidebarItem';
import { USER_ROLES } from '../../../../config/constants';
import { useTranslation } from '../../../../config/i18n';
// const { getRouteName } = require('../../../../server/routes');

const Lists = ({ children }) => <List>{children}</List>;

Lists.propTypes = {
  children: PropTypes.node,
};

Lists.defaultProps = {
  children: <></>,
};

export const organisationList = ({ fieldsExpand, setFieldsExpand, sidebarOpen }) => {
  const { t } = useTranslation('common');
  const { client } = useQuery(GET_SIDEBAR_STATUS);
  const router = useRouter();

  const { data } = useQuery(USER_QUERY);

  const [fieldActive, setFieldActive] = useState({
    events: false,
    subbmisions: false,
    reviews: false,
    organizations: false,
    explore: false,
    paymentMethods: false,
  });

  React.useEffect(() => {
    if (
      router.pathname === '/opportunity-wizard' ||
      router.pathname === '/opportunities/my-opportunities' ||
      router.pathname === '/committees'
    ) {
      setFieldActive({
        ...fieldActive,
        events: !fieldActive.events,
      });
    } else if (
      router.pathname === '/organizations/create' ||
      router.pathname === '/organizations/my-organizations'
    ) {
      setFieldActive({
        ...fieldActive,
        organizations: !fieldActive.organizations,
      });
    }
  }, []);

  const handleSideBar = () => {
    client.writeQuery({
      query: GET_SIDEBAR_STATUS,
      data: { sidebarOpen: true },
    });
  };

  return (
    <List>
      <SidebarItem
        text={t('my_profile')}
        toolTipText={sidebarOpen ? undefined : t('my_profile')}
        showTooltip={fieldsExpand}
        icon={<AssignmentIndIcon />}
        link="update_profile"
        fieldsExpand={fieldsExpand}
        setFieldsExpand={setFieldsExpand}
      />

      {/* <SidebarItem
        text="Invitations"
        icon={<EmailIcon />}
        link="user_invitations"
        fieldsExpand={fieldsExpand}
        setFieldsExpand={setFieldsExpand}
      /> */}

      {/* <SidebarItem
        text='My connections'
        icon={<AssignmentIndIcon />}
        fieldsExpand={fieldsExpand}
        link=""
        setFieldsExpand={setFieldsExpand}
      /> */}

      {isOrganiser(data.me) && (
        <StyledAccordion
          expanded={fieldsExpand.events}
          onChange={() =>
            setFieldsExpand({
              ...fieldsExpand,
              events: !fieldsExpand.events,
            })
          }
          onClick={handleSideBar}
          sx={{ flexDirection: 'column' }}
        >
          <Tooltip title={sidebarOpen ? undefined : t('events')} placement="right">
            <StyledAccordionSummary isActive={fieldActive.events} expandIcon={<ExpandMore />}>
              <EventIcon
                sx={{
                  minWidth: 'initial',
                  color: colors.grey[500],
                }}
              />
              <Typography
                sx={{
                  pl: '1rem',
                  color: colors.grey[600],
                }}
              >
                {t('events')}
              </Typography>
            </StyledAccordionSummary>
          </Tooltip>
          <StyledAccordionDetails>
            <ExpandSidebarItem
              text={t('common:create_event')}
              link="wizard_home"
              setFieldsExpand={setFieldsExpand}
            />
            <ExpandSidebarItem
              text={t('common:my_events')}
              link="my_opportunities"
              setFieldsExpand={setFieldsExpand}
            />
            {/* <ExpandSidebarItem
              text="My committee participation"
              link="user_invitations"
              setFieldsExpand={setFieldsExpand}
            /> */}
          </StyledAccordionDetails>
        </StyledAccordion>
      )}

      {/* <SidebarItem
        text="My applications"
        icon={<RateReviewIcon />}
        link="my_applications"
        fieldsExpand={fieldsExpand}
        setFieldsExpand={setFieldsExpand}
      /> */}
      <StyledAccordion
        expanded={fieldsExpand.subbmisions}
        onChange={() =>
          setFieldsExpand({
            ...fieldsExpand,
            subbmisions: !fieldsExpand.subbmisions,
          })
        }
        onClick={handleSideBar}
        sx={{ flexDirection: 'column' }}
      >
        <Tooltip title={sidebarOpen ? undefined : t('my_app')} placement="right">
          <StyledAccordionSummary isActive={fieldActive.subbmisions} expandIcon={<ExpandMore />}>
            <RateReviewIcon
              sx={{
                minWidth: 'initial',
                color: colors.grey[500],
              }}
            />
            <Typography
              sx={{
                pl: '1rem',
                color: colors.grey[600],
              }}
            >
              {t('my_app')}
            </Typography>
          </StyledAccordionSummary>
        </Tooltip>
        <StyledAccordionDetails>
          <ExpandSidebarItem
            text={t('common:paper_sub')}
            link="my_applications"
            setFieldsExpand={setFieldsExpand}
          />
          <ExpandSidebarItem
            text={t('common:registrations')}
            link="my_registrations"
            setFieldsExpand={setFieldsExpand}
          />
        </StyledAccordionDetails>
      </StyledAccordion>

      <SidebarItem
        text={t('my_comm_part')}
        toolTipText={sidebarOpen ? undefined : t('my_comm_part')}
        showTooltip={fieldsExpand}
        icon={<PeopleAltIcon />}
        link="user_invitations"
        fieldsExpand={fieldsExpand}
        setFieldsExpand={setFieldsExpand}
      />

      <SidebarItem
        text={t('my_rev')}
        toolTipText={sidebarOpen ? undefined : t('my_rev')}
        showTooltip={fieldsExpand}
        icon={<AssignmentIndIcon />}
        link="review_opportunities"
        fieldsExpand={fieldsExpand}
        setFieldsExpand={setFieldsExpand}
      />

      <StyledAccordion
        expanded={fieldsExpand.organizations}
        onChange={() =>
          setFieldsExpand({
            ...fieldsExpand,
            organizations: !fieldsExpand.organizations,
          })
        }
        onClick={handleSideBar}
        sx={{ flexDirection: 'column' }}
      >
        <Tooltip title={sidebarOpen ? undefined : t('org')} placement="right">
          <StyledAccordionSummary isActive={fieldActive.organizations} expandIcon={<ExpandMore />}>
            <BusinessRoundedIcon
              sx={{
                minWidth: 'initial',
                color: colors.grey[500],
              }}
            />
            <Typography
              sx={{
                pl: '1rem',
                color: colors.grey[600],
              }}
            >
              {t('org')}
            </Typography>
          </StyledAccordionSummary>
        </Tooltip>
        <StyledAccordionDetails>
          <ExpandSidebarItem
            text={t('create_org')}
            link="organisation_create"
            setFieldsExpand={setFieldsExpand}
          />
          <ExpandSidebarItem
            text={t('my_org')}
            link="my_organisations"
            setFieldsExpand={setFieldsExpand}
          />
        </StyledAccordionDetails>
      </StyledAccordion>

      {/*  <SidebarItem
        text="Explore "
        icon={<ExploreIcon />}
        link="opportunity_search"
        fieldsExpand={fieldsExpand}
        setFieldsExpand={setFieldsExpand}
          /> */}

      <StyledAccordion
        expanded={fieldsExpand.explore}
        onChange={() =>
          setFieldsExpand({
            ...fieldsExpand,
            explore: !fieldsExpand.explore,
          })
        }
        onClick={handleSideBar}
        sx={{ flexDirection: 'column' }}
      >
        <Tooltip title={sidebarOpen ? undefined : t('explore')} placement="right">
          <StyledAccordionSummary isActive={fieldActive.explore} expandIcon={<ExpandMore />}>
            <ExploreIcon
              sx={{
                minWidth: 'initial',
                color: colors.grey[500],
              }}
            />
            <Typography
              sx={{
                pl: '1rem',
                color: colors.grey[600],
              }}
            >
              {t('explore')}
            </Typography>
          </StyledAccordionSummary>
        </Tooltip>
        <StyledAccordionDetails>
          <ExpandSidebarItem
            text={t('events')}
            link="opportunity_search"
            setFieldsExpand={setFieldsExpand}
          />
          <ExpandSidebarItem
            text={t('people')}
            link="scientists_search"
            setFieldsExpand={setFieldsExpand}
          />
          <ExpandSidebarItem
            text={t('org')}
            link="organisations_search"
            setFieldsExpand={setFieldsExpand}
          />
        </StyledAccordionDetails>
      </StyledAccordion>

      <SidebarItem
        text={t('payment_methods')}
        toolTipText={sidebarOpen ? undefined : t('payment_methods')}
        showTooltip={fieldsExpand}
        icon={<MonetizationOnIcon />}
        link="user_payments_setup"
        fieldsExpand={fieldsExpand}
        setFieldsExpand={setFieldsExpand}
      />
      {/* <SidebarItem
        text="Inbox"
        icon={<MessageOutlined />}
        link="user_inbox"
        fieldsExpand={fieldsExpand}
        setFieldsExpand={setFieldsExpand}
      /> */}

      {/* <StyledAccordion
        expanded={fieldsExpand.explore}
        onChange={() =>
          setFieldsExpand({
            ...fieldsExpand,
            explore: !fieldsExpand.explore,
          })
        }
        onClick={handleSideBar}
        sx={{ flexDirection: 'column' }}
      >
        <StyledAccordionSummary isActive={fieldActive.paymentMethods} expandIcon={<ExpandMore />}>
          <MonetizationOnIcon
            style={{
              minWidth: 'initial',
              color: colors.grey[500],
            }}
          />
          <Typography
            sx={{
              pl: '1rem',
              color: colors.grey[600],
            }}
          >
            Payment methods
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails></StyledAccordionDetails>
          </StyledAccordion> */}
    </List>
  );
};

export const GeneralList = ({ role, hasReviews, fieldsExpand, setFieldsExpand, sidebarOpen }) => {
  const { t } = useTranslation('common');
  const { client } = useQuery(GET_SIDEBAR_STATUS);

  const handleSideBar = () => {
    client.writeQuery({
      query: GET_SIDEBAR_STATUS,
      data: { sidebarOpen: true },
    });
  };

  const [fieldActive] = useState({
    events: false,
    subbmisions: false,
    reviews: false,
    organizations: false,
    explore: false,
    paymentMethods: false,
  });

  return (
    <List>
      {/* <SidebarItem
        text={t('explore_opportunities')}
        icon={<LibraryMusicRoundedIcon />}
        link="opportunity_search"
      /> */}

      {/* <SidebarItem
        text={t('my_applications')}
        icon={<AssignmentIcon />}
        link="my_applications"
      /> */}

      {/* {hasReviews && <SidebarItem
        // text={t('my_applications')}
        text='Reviewing'
        icon={<RateReviewIcon />}
        link="review_opportunities"
      />} */}

      {role === USER_ROLES.USER && (
        <>
          <SidebarItem
            text="My profile"
            toolTipText={sidebarOpen ? undefined : 'My profile'}
            showTooltip={fieldsExpand}
            icon={<AssignmentIndIcon />}
            link="update_profile"
            fieldsExpand={fieldsExpand}
            setFieldsExpand={setFieldsExpand}
          />

          <StyledAccordion
            expanded={fieldsExpand.subbmisions}
            onChange={() =>
              setFieldsExpand({
                ...fieldsExpand,
                subbmisions: !fieldsExpand.subbmisions,
              })
            }
            onClick={handleSideBar}
            sx={{ flexDirection: 'column' }}
          >
            <Tooltip title={sidebarOpen ? undefined : t('my_app')} placement="right">
              <StyledAccordionSummary isActive={fieldActive.subbmisions} expandIcon={<ExpandMore />}>
                <RateReviewIcon
                  sx={{
                    minWidth: 'initial',
                    color: colors.grey[500],
                  }}
                />
                <Typography
                  sx={{
                    pl: '1rem',
                    color: colors.grey[600],
                  }}
                >
                  {t('my_app')}
                </Typography>
              </StyledAccordionSummary>
            </Tooltip>
            <StyledAccordionDetails>
              <ExpandSidebarItem
                text={t('common:paper_sub')}
                link="my_applications"
                setFieldsExpand={setFieldsExpand}
              />
              <ExpandSidebarItem
                text={t('common:registrations')}
                link="my_registrations"
                setFieldsExpand={setFieldsExpand}
              />
            </StyledAccordionDetails>
          </StyledAccordion>

          {/* <SidebarItem
            text="My applications"
            toolTipText={sidebarOpen ? undefined : 'My applications'}
            showTooltip={fieldsExpand}
            icon={<RateReviewIcon />}
            link="my_applications"
            fieldsExpand={fieldsExpand}
            setFieldsExpand={setFieldsExpand}
          /> */}

          <SidebarItem
            text={t('my_comm_part')}
            icon={<PeopleAltIcon />}
            toolTipText={sidebarOpen ? undefined : 'My committee participation'}
            showTooltip={fieldsExpand}
            link="user_invitations"
            fieldsExpand={fieldsExpand}
            setFieldsExpand={setFieldsExpand}
          />

          <SidebarItem
            text={t('my_rev')}
            icon={<AssignmentIndIcon />}
            toolTipText={sidebarOpen ? undefined : 'My reviews'}
            showTooltip={fieldsExpand}
            link="review_opportunities"
            fieldsExpand={fieldsExpand}
            setFieldsExpand={setFieldsExpand}
          />

          <SidebarItem
            text={t('upgrade_account')}
            toolTipText={sidebarOpen ? undefined : t('upgrade_account')}
            showTooltip={fieldsExpand}
            icon={<ArrowUpwardIcon />}
            link="account_upgrade"
          />
          <SidebarItem
            text="Explore events"
            toolTipText={sidebarOpen ? undefined : 'Explore events'}
            showTooltip={fieldsExpand}
            icon={<ExploreIcon />}
            link="opportunity_search"
            fieldsExpand={fieldsExpand}
            setFieldsExpand={setFieldsExpand}
          />
        </>
      )}
    </List>
  );
};

GeneralList.propTypes = {
  role: PropTypes.string.isRequired,
  hasReviews: PropTypes.bool,
};

GeneralList.defaultProps = {
  hasReviews: false,
};

Lists.organisationList = organisationList;
Lists.GeneralList = GeneralList;

export default Lists;
